import React from 'react'
import './style.css'
import styled from 'styled-components'

import { Link } from 'gatsby'
import logo from '../images/sz-logo.png'
import bg from '../images/bg_header.png'
import icon16 from '../images/favicon-16x16.png'
import icon32 from '../images/favicon-32x32.png'
import {Helmet} from "react-helmet";

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100%;
    background-color: ${(props) => (props.home ? 'white' : '#393c40')};
`
export const StyledMain = styled.main`
    flex-grow: 1;
    width: 900px;
    margin: 0 auto;

    color: ${(props) => (props.home ? '#242424' : 'whitesmoke')};
    @media (max-width: 900px) {
        width: 100%;
    }
  article{
    margin:0 1rem;
    img{

    }
  }
`

export default function Layout({ children, home }) {
    return (
        <StyledContent home={home}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Sončna Zadruga</title>
                <link
                    rel="icon"
                    type="image/png"
                    sizes="32x32"
                    href={icon32}
                />
                <link
                    rel="icon"
                    type="image/png"
                    sizes="16x16"
                    href={icon16}
                />
            </Helmet>
            <Header home={home} />
            <StyledMain home={home}>{children}</StyledMain>
            <Footer />
        </StyledContent>
    )
}

export const Footer = () => {
    return (
        <FooterDiv>
            <Columns>
                <Column>
                    <p>
                    Sončna zadruga, zelena energija, z.b.o., so.p.
                    <br />
                    Sneberska c. 15a
                    <br />
                    1260 Ljubljana Polje
                    </p>
                </Column>
                <Column style={{ textAlign: 'right' }}>
                    <p>
                    tel: <a href="tel:041495004">041 495 004</a>
                    <br />
                    mail:{' '}
                    <a href="mailto:info@soncnazadruga">
                        info@soncnazadruga.si
                    </a>
                    </p>
                </Column>
            </Columns>
        </FooterDiv>
    )
}
const FooterDiv = styled.div`
    justify-self: flex-end;
    background-color: #1e1e1e;
`
const Column = styled.div`
    flex: 1 1 40%;
  padding:0 1rem;
`
const Columns = styled.div`
    min-height: 5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 900px;
    min-width: 900px;
    margin: 1rem auto;
    color: #646363;


    @media (max-width: 900px) {
        min-width: 100%;

    }
`

export const Header = ({ home }) => {
    return (
        <HeaderStyle home={home}>
            <HeaderContent home={home}>
                <ImgDiv>
                    <Link to="/">
                        <img src={logo} alt="Logo EU kohezijski sklad" />
                    </Link>
                </ImgDiv>
                {false && (
                    <HeadDiv>
                        <h1>SONČNA ZADRUGA</h1>

                        <h2>
                            Skupaj do zelene energije in energetske samooskrbe.
                        </h2>
                    </HeadDiv>
                )}
            </HeaderContent>
        </HeaderStyle>
    )
}

const HeaderStyle = styled.div`
    flex-grow: 0;
    width: 100%;

    background-image: url(${bg});
    background-size: cover;
    background-position-y: 30%;
    ${(props) => (!props.home && "background:whitesmoke;")};
`
const HeaderContent = styled.div`
    display: flex;
    flex-wrap: nowrap;
    max-width: 900px;
    min-width: 900px;
    margin: 0 auto;
    padding: 1rem 0;
    justify-content: ${(props) => (props.home ? 'center' : 'center')};

    img {
        max-width: ${(props) => (props.home ? '13rem' : '10rem')};
    }

    @media (max-width: 900px) {
        min-width: 90%;
        
        img {
            max-width: 8rem;
        }
    }
    @media (max-width: 400px) {
        flex-wrap: wrap;
        img {
            max-width: 5rem;
        }
    }
`

const ImgDiv = styled.div`
    //margin-right: 2rem;
    display: flex;
    align-items: center;
`
const HeadDiv = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;

    h1,
    h2 {
        margin: 0.2rem 0;
        color: white;
    }

    @media (max-width: 768px) {
        padding-right: 1rem;
        h1 {
            font-size: 1.1em;
        }

        h2 {
            font-size: 0.9em;
        }
    }
`
